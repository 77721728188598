import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as orderService from 'service/order.service';
import { PunchOrderFlowState } from './punchOrderFlow.types';
import { SettingsState } from 'store/settings/settings.reducer';
import { PunchOrderFlowDiscountsState } from 'store/punchOrderFlowDiscounts/punchOrderFlowDiscounts.types';
import { dialog } from 'utils/dialog';
import store, { AppDispatch } from 'store';
import { getBranchesForPOS } from 'service/branch.service';
import { setCurrency } from './punchOrderFlow.actions';
import { getCreateOrderRequestBody } from './utils/getCreateOrderRequestBody.util';
import { Kitchen } from 'types/Kitchen';
import { getDeliveryAreasForKitchenAndBrand, getDeliveryAreas } from 'service/deliveryArea.service';
import { OrderPriceDetails } from 'types/OrderPriceDetails';
import { OrderPosition } from 'types/OrderPosition';
import { getValidateOrderRequestBody } from './utils/getValidateOrderRequestBody.util';
import { FeatureFlags } from 'utils/constants';

export * from './orderBody/orderBody.actions';
export * from './orderDetails/orderDetails.actions';
export * from './orderSource/orderSource.actions';
export * from './selectedCustomer/selectedCustomer.actions';

export interface OrderCreationParams {
  compositionTime: number;
  features?: FeatureFlags;
}

export const punchOrder = createAsyncThunk<
  unknown,
  OrderCreationParams,
  {
    state: {
      punchOrderFlow: PunchOrderFlowState;
      settings: SettingsState;
      punchOrderFlowDiscounts: PunchOrderFlowDiscountsState;
    };
  }
>('punchOrderFlow/punchOrder', (orderParams: OrderCreationParams, { getState }) => {
  const state = getState();
  const { draftId } = state.punchOrderFlow.orderSource;
  const isNoDraftPunchingOrderFlow = orderParams.features?.noDraftPunchingOrderFlow;
  if (isNoDraftPunchingOrderFlow) {
    const requestBody = getCreateOrderRequestBody(state, orderParams.compositionTime);
    if (!requestBody) {
      const message = 'Lack of order body. Please try to create order again.';
      dialog.error({ message, title: '' });
      return Promise.reject(message);
    }
    return orderService.punchNewOrderWithoutDraft({ orderBody: requestBody });
  } else {
    if (!draftId) {
      const message = 'Lack of order draft identifier. Please try to create order again.';
      dialog.error({ message, title: '' });
      return Promise.reject(message);
    }

    const requestBody = getCreateOrderRequestBody(state, orderParams.compositionTime);

    if (!requestBody) {
      const message = 'Lack of order body. Please try to create order again.';
      dialog.error({ message, title: '' });
      return Promise.reject(message);
    }

    return orderService.punchNewOrder({
      draftId,
      orderBody: requestBody,
    });
  }
});

export const setInitialState = createAction<PunchOrderFlowState>('punchOrderFlow/setInitialState');

export const fetchBranchesForPOS = createAsyncThunk(
  'punchOrderFlow/fetchBranchesForPOS',
  getBranchesForPOS
);

export const setCustomDeliveryFee = createAction<string>('punchOrderFlow/setCustomDeliveryFee');

export const resetPunchOrderFlow = createAction('punchOrderFlow/resetPunchOrderFlow');

export const setRegionCurrency =
  (regionId: string) => (dispatch: AppDispatch, getState: typeof store.getState) => {
    const region = getState().metadata.regions.find(region => region.id === regionId);
    const country = getState().metadata.countries.find(country => country.id === region?.countryId);
    dispatch(setCurrency(country?.currency || null));
  };

export const resolveKitchen = createAction<Kitchen | 'KITCHEN_NOT_AVAILABLE' | null>(
  'punchOrderFlow/resolveKitchen'
);

export const setDefaultKitchenForBrandAndDeliveryArea = createAction(
  'punchOrderFlow/setDefaultKitchenForBrandAndDeliveryArea'
);

export const getDeliveryAreasForBranch = createAsyncThunk(
  'punchOrderFlow/getDeliveryAreasForBranch',
  getDeliveryAreasForKitchenAndBrand
);

export const getDeliveryAreasForRegion = createAsyncThunk(
  'punchOrderFlow/getDeliveryAreasForRegion',
  getDeliveryAreas
);

export const assignDeliveryPartner = createAsyncThunk(
  'punchOrderFlow/assignDeliveryPartner',
  orderService.assignDeliveryPartner
);

export const applyDiscounts = createAsyncThunk<
  {
    orderPriceDetails: OrderPriceDetails;
    orderPositions: OrderPosition[] | undefined;
  },
  undefined,
  {
    state: {
      punchOrderFlow: PunchOrderFlowState;
      punchOrderFlowDiscounts: PunchOrderFlowDiscountsState;
      settings: SettingsState;
    };
  }
>('punchOrderFlow/applyDiscounts', (_, { getState }) => {
  const { punchOrderFlow, punchOrderFlowDiscounts, settings } = getState();

  const pointOfSaleId = punchOrderFlow.orderSource.selectedPos?.pointOfSaleId;
  const requestBody = getValidateOrderRequestBody({
    punchOrderFlow,
    punchOrderFlowDiscounts,
    settings,
  });

  if (!requestBody) {
    return Promise.reject();
  }

  return orderService
    .validateOrder({
      posID: pointOfSaleId,
      orderBody: requestBody,
    })
    .then(({ orderPriceDetails, orderPositions }) => ({
      orderPriceDetails: {
        ...orderPriceDetails,
        taxAmount: orderPriceDetails?.taxes?.[0]?.taxValue ?? 0,
      },
      orderPositions,
    }));
});
