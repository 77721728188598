import { Url } from 'utils/constants';
import { Order } from 'types/Order';
import { OrderModificationReason } from 'types/OrderModificationReason';
import { CreateOrder } from 'types/CreateOrder';
import { OrderListElement } from 'types/OrderListElement';
import { OrderList } from 'types/OrderList';
import { OrderStatus } from 'types/OrderStatus';
import { OrderType } from 'types/OrderType';
import { FinalDeliveryType } from 'types/FinalDeliveryType';
import { OrderAddress } from 'types/OrderAddress';
import { CreateOrderPosition } from 'types/CreateOrderPosition';
import { UpdateOrderPosition } from 'types/UpdateOrderPosition';
import { DeliveryDetails } from 'types/DeliveryDetails';
import { OrderPriceAdjustments } from 'types/OrderPriceAdjustments';
import { List } from 'types/List';
import { ResourceLocation } from 'types/ResourceLocation';
import { ReassignDeliveryPartnerReason } from 'types/ReassignDeliveryPartnerReason';
import { OrderModification } from 'types/OrderModification';
import { Nullable } from 'types/Nullable';
import { OrderValidationResult } from 'types/OrderValidationResult';
import { ValidateOrder } from 'types/ValidateOrder';
import { InternalOrderNote } from 'types/InternalOrderNote';
import { ParcelCheckIn } from 'types/ParcelCheckIn';
import { getAllPages, api } from 'api';

export const punchNewOrder = ({
  draftId,
  orderBody,
}: {
  draftId: string;
  orderBody: CreateOrder;
}): Promise<void> =>
  api.put(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/drafts/${draftId}/create`, orderBody);

export const punchNewOrderWithoutDraft = ({
  orderBody,
}: {
  orderBody: CreateOrder;
}): Promise<void> => api.post(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/create`, orderBody);

interface GetOrdersOptions {
  signal?: AbortSignal;
  isFetchingAllPages?: boolean;
  orderNumber?: string;
  orderStatuses?: OrderStatus[];
  orderTypes?: OrderType[];
  finalDeliveryType?: FinalDeliveryType | null;
  deliveryPartnerId?: string | null;
  kitchenId?: string | null;
  regionId?: string | null;
  pointsOfSaleId?: string[];
  phoneNumber?: string | null;
  brandId?: string | null;
  ordersIds?: string[];
  businessId?: string | null;
  dateFrom: string;
  dateTo: string;
  page?: number;
  perPage?: number;
  sort?: string;
  sortOrder?: string;
  customerId?: string | null;
}

export const getOrders = ({
  signal,
  isFetchingAllPages,
  orderNumber,
  orderStatuses = [],
  orderTypes = [],
  finalDeliveryType,
  deliveryPartnerId,
  kitchenId,
  regionId,
  pointsOfSaleId = [],
  phoneNumber,
  brandId,
  ordersIds = [],
  businessId,
  dateFrom,
  dateTo,
  page,
  perPage,
  sort,
  sortOrder,
  customerId,
}: GetOrdersOptions): Promise<OrderList<OrderListElement>> => {
  const apiMethod = isFetchingAllPages ? getAllPages : api.get;
  return apiMethod(`${Url.ORDER_READ_API_URL}/orders`, {
    params: {
      page,
      perPage,
      sort,
      orderType: orderTypes,
      finalDeliveryType,
      deliveryPartnerId,
      kitchenId,
      regionId,
      pointOfSaleId: pointsOfSaleId,
      brandId,
      businessId,
      sortOrder,
      customerPhoneNumber: phoneNumber,
      number: orderNumber,
      orderStatus: orderStatuses,
      orderId: ordersIds,
      createdDateTimeFrom: dateFrom,
      createdDateTimeTo: dateTo,
      customerId,
    },
    signal,
  });
};

export const cancelOrder = ({
  posID,
  orderID,
  cancelReason,
  isScheduledForFullRefund,
}: {
  posID: string;
  orderID: string;
  cancelReason: string;
  isScheduledForFullRefund?: boolean;
}): Promise<void> =>
  api.delete(`${Url.KITCHEN_MANAGEMENT_API_URL}/point-of-sales/${posID}/orders/${orderID}`, {
    data: {
      reason: cancelReason,
      isScheduledForFullRefund,
    },
  });

export const setOrderAsDelivered = ({
  pointOfSaleId,
  orderId,
}: {
  pointOfSaleId: string;
  orderId: string;
}): Promise<void> =>
  api.put(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/point-of-sales/${pointOfSaleId}/orders/${orderId}/delivered`
  );

export const getOrder = ({ orderID }: { orderID: string }): Promise<Order> =>
  api.get(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderID}`);

export const getDeliveryDetails = ({ orderID }: { orderID: string }): Promise<DeliveryDetails> =>
  api.get(`${Url.DELIVERY_HUB_API_URL}/deliveries/${orderID}`, {
    hasErrorDialog: false,
  });

export const reassignDeliveryPartner = ({
  deliveryPartnerInRegionId,
  reason,
  orderId: parcelId,
}: {
  deliveryPartnerInRegionId: string;
  orderId: string;
  reason: ReassignDeliveryPartnerReason;
}): Promise<void> =>
  api.put(`${Url.DELIVERY_HUB_API_URL}/parcels/${parcelId}/reassign`, {
    deliveryPartnerInRegionId,
    reason,
  });

export const assignDeliveryPartner = ({
  deliveryPartnerInRegionId,
  orderId: parcelId,
}: {
  deliveryPartnerInRegionId: string;
  orderId: string;
}): Promise<void> =>
  api.put(`${Url.DELIVERY_HUB_API_URL}/parcels/${parcelId}/preferred-dp`, {
    deliveryPartnerInRegionId,
  });

export const getParcelCheckInTime = ({
  orderId: parcelId,
}: {
  orderId: string;
}): Promise<ParcelCheckIn> => api.get(`${Url.DELIVERY_HUB_API_URL}/parcels/${parcelId}/check-in`);

export const validateOrder = (
  { posID, orderBody }: { posID: string | undefined; orderBody: ValidateOrder },
  { signal, hasErrorDialog = true }: { signal?: AbortSignal; hasErrorDialog?: boolean } = {}
): Promise<OrderValidationResult> =>
  api.post(`${Url.KITCHEN_MANAGEMENT_API_URL}/point-of-sales/${posID}/orders/validate`, orderBody, {
    hasErrorDialog,
    signal,
  });

export const createDraft = ({
  pointOfSaleId,
  sourceOrderFriendlyId,
}: {
  pointOfSaleId: string;
  sourceOrderFriendlyId?: string;
}): Promise<ResourceLocation> =>
  api.post(
    `${Url.KITCHEN_MANAGEMENT_API_URL}/orders/drafts`,
    {
      pointOfSaleId,
      sourceOrderFriendlyId: sourceOrderFriendlyId ? sourceOrderFriendlyId : undefined,
    },
    {
      hasResourceLocation: true,
    }
  );

export const getPotentialDuplicates = ({
  pointOfSaleId,
  sourceOrderFriendlyId,
  isNoDraftOrderFlow,
}: {
  pointOfSaleId: string;
  sourceOrderFriendlyId: string;
  isNoDraftOrderFlow?: boolean;
}): Promise<List<OrderListElement>> =>
  isNoDraftOrderFlow
    ? api.get(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/duplicates`, {
        params: { pointOfSaleId, sourceOrderFriendlyId },
      })
    : api.get(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/drafts/duplicates`, {
        params: { pointOfSaleId, sourceOrderFriendlyId },
      });

export const updateOrderNote = ({
  orderID,
  note,
}: {
  orderID: string;
  note: string;
}): Promise<void> => api.put(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderID}/notes`, { note });

export const updateOrderAddress = ({
  orderID,
  address,
}: {
  orderID: string;
  address: Omit<OrderAddress, 'coordinates' | 'customAddressLabel'>;
}): Promise<void> =>
  api.put(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderID}/address`, address);

export const updateOrderPositionNote = ({
  orderId,
  positionId,
  note,
}: {
  orderId: string;
  positionId: string;
  note: string;
}): Promise<void> =>
  api.put(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderId}/positions/${positionId}/notes`, {
    note,
  });

interface UpdateOrder {
  orderId: string;
  orderPositionsToAdd: CreateOrderPosition[];
  orderPositionsToUpdate: UpdateOrderPosition[];
  orderPositionsToDelete: string[];
  orderDiscountId?: string | null;
  orderDeliveryDiscountId?: string;
  modificationReason?: OrderModificationReason;
  priceAdjustments?: OrderPriceAdjustments;
  walletPaymentAmount: Nullable<number>;
}

export const updateOrder = ({
  orderId,
  orderPositionsToAdd,
  orderPositionsToUpdate,
  orderPositionsToDelete,
  orderDiscountId,
  orderDeliveryDiscountId,
  modificationReason,
  priceAdjustments,
  walletPaymentAmount,
}: UpdateOrder): Promise<void> =>
  api.put(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderId}/positions`, {
    orderPositionsToAdd,
    orderPositionsToUpdate,
    orderPositionsToDelete,
    orderDiscountId,
    orderDeliveryDiscountId,
    modificationReason,
    priceAdjustments,
    walletPaymentAmount,
  });

export const assignAgent = ({ orderId }: { orderId: string }): Promise<void> =>
  api.post(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderId}/customer-contacts`);

export const getOrderModifications = ({
  orderId,
}: {
  orderId: string;
}): Promise<List<OrderModification>> =>
  api.get(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderId}/updates`);

export const getInternalOrderNotes = ({
  orderId,
}: {
  orderId: string;
}): Promise<List<InternalOrderNote>> =>
  api.get(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderId}/internal-notes`);

export const addInternalOrderNote = ({
  orderId,
  note,
}: {
  orderId: string;
  note: string;
}): Promise<void> =>
  api.post(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderId}/internal-notes`, {
    note,
  });

export const resolveInternalOrderNote = ({
  orderId,
  noteId,
}: {
  orderId: string;
  noteId: string;
}): Promise<void> =>
  api.put(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderId}/internal-notes/${noteId}`);

export const deleteInternalOrderNote = ({
  orderId,
  noteId,
}: {
  orderId: string;
  noteId: string;
}): Promise<void> =>
  api.delete(`${Url.KITCHEN_MANAGEMENT_API_URL}/orders/${orderId}/internal-notes/${noteId}`);
